$theme-font: "Noto Sans Display", Noto Sans Display fallback;
$theme-color: #045de9;
$theme-dark: #174a9c;
$theme-dark2: #0E3778;
$theme-medium: #a6c8ff;
$theme-medium2: #5090F6;
$theme-light: #e7f0ff;
$theme-light2: #F5F9FF;
$dark-bg: #0d121a;
$font-color: #23272E;
$font-dark: #3b404a;
$font-light: #757a82;
$font-light2: #7c7c80;
$secondary: #323842;
$light-bg: #f2f4f7;
$light: #e4e6eb;
$danger: #f14f4f;
$warning: #ff870b;
$success: #14b305;
$success-bg: #9AD9BE;
$danger-bg: #ffcccc;
$border-color: #50555d;
$border-dark: #979797;
$border-dark2: #a7acb4;
$border-medium: #ebebf0;
$border-light: #d2d5d9;
$border-light2: #D8D8D8;
$border-input: #dedfe3;
$brand-dream11: #DB0100;
$brand-11Wickets: #7641A4;
$brand-my11cir: #E42400;
$brand-howzat: #ce0200;
$brand-gamezy: #2400A8;
$light-color: #fff;
$common-transition: all 0.24s ease-in-out;
$theme-gradient: linear-gradient(261.07deg, #a628ec 4.18%, #803be6 107.85%);
